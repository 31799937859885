@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$cell-gap: 0;
$h-padding: 0px;
$border: 1px;
$add-padding: 2px;
$deck-min-length: 922px;
$deck-width: 302px;

$padding-top: 40px;
$padding-bottom: 40px;

$head-padding-top: 140px;

.seat-selection__deck-wrapper {
  --deck-rotation: 0deg;
  --padding-top: 40px;

  --rows: 15;
  --columns: 20;

  @function calc-grid-size($items, $cell-size) {
    @return calc($cell-size * $items + $h-padding * 2 + $border * 2 + var(--padding-top) + $padding-bottom);
  }

  @function calc-cell-size($items, $container-width) {
    @return calc(($container-width - $h-padding * 2 - $border * 2) / $items);
  }

  $cell-size: calc-cell-size(var(--rows), $deck-width);
  $deck-length: calc-grid-size(var(--columns), $cell-size);

  overflow: hidden;
  position: relative;

  background: $color-seat-selection-bg;
  border: $border solid $color-seat-selection-border;
  box-shadow: 0 0 12px rgb(11 8 39 / 8%);
  border-radius: 40px;

  .seat-selection__deck {
    position: relative;
    display: grid;
    gap: $cell-gap;

    transform-origin: 150px 150px;
    rotate: var(--deck-rotation);
  }

  &--up {
    --deck-rotation: 90deg;
    justify-content: center;

    width: $deck-width;
    height: max-content;
    min-height: $deck-length;
    padding: var(--padding-top) $h-padding $padding-bottom;

    .seat-selection__deck {
      grid-auto-columns: minmax($cell-size, 0);
      grid-auto-rows: minmax($cell-size, 0);
    }

    .deck-counter-rotate {
      rotate: calc(270deg);
    }

    .deck-item {
      &--facingForward .deck-counter-rotate {
        rotate: calc(270deg);
      }

      &--facingRight .deck-counter-rotate {
        rotate: calc(180deg);
      }

      &--facingBackward .deck-counter-rotate {
        rotate: calc(90deg);
      }

      &--facingLeft .deck-counter-rotate {
        rotate: calc(0deg);
      }
    }
  }

  &--right {
    height: $deck-width;
    width: max-content;
    min-width: $deck-length;
    padding: var(--padding-top) $h-padding $padding-bottom;

    .seat-selection__deck {
      grid-auto-rows: minmax($cell-size, 0);
      grid-auto-columns: minmax($cell-size, 0);
    }
  }

  &--head {
    --padding-top: 140px;

    border-radius: 140px 140px 40px 40px;
  }

  .seat-selection__extra-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .deck-item {
    &--facingForward {
      rotate: 0deg;
    }

    &--facingRight {
      rotate: 90deg;
    }

    &--facingBackward {
      rotate: 180deg;
    }

    &--facingLeft {
      rotate: 270deg;
    }
  }
}
