@font-face {
  font-family: 'distribusion-icons';
  src: url('/public/fonts/distribusion-icons/distribusion-icons.eot?t=1731321161016'); /* IE9*/
  src:
    url('/public/fonts/distribusion-icons/distribusion-icons.eot?t=1731321161016#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/public/fonts/distribusion-icons/distribusion-icons.woff2?t=1731321161016') format('woff2'),
    url('/public/fonts/distribusion-icons/distribusion-icons.woff?t=1731321161016') format('woff'),
    url('/public/fonts/distribusion-icons/distribusion-icons.ttf?t=1731321161016') format('truetype'),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
      url('/public/fonts/distribusion-icons/distribusion-icons.svg?t=1731321161016#distribusion-icons') format('svg'); /* iOS 4.1- */
}

.font-icon__content {
  font-family: 'distribusion-icons' !important;
  font-size: undefined;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
