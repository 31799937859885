@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.checkout-trip {
  &__operated-by {
    @include font(12px, 16px, 400);
    @include gap(4px);

    margin-top: map-get($gap-values, 3);
  }

  &__dates {
    @include font(16px, 24px, 600);

    margin-bottom: map-get($gap-values, 2);
  }

  .stations__time,
  .stations__term-departure,
  .stations__term-arrival {
    font-weight: 400;
  }

  &__conditions {
    margin-top: map-get($gap-values, 3);
  }
}
