@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$gap: 65px;
$v-gap: 20px;
$h-gap: 8px;

.journey-list {
  border-radius: 24px 24px 0 0;
  font-family: $font-secondary;
  padding: 0 $v-gap $v-gap;
  overflow: auto;
  min-height: 0;
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;

  &__backdrop {
    position: sticky;
    top: 0;
    transform: translateX(-20px);
    z-index: -1;

    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      left: 0;
      background: $white;
      border-radius: 24px 24px 0 0;
      height: 100vh;
      width: 100vw;
    }
  }

  @include on-frame {
    position: relative;
    height: 100%;
    padding: 0 0 $v-gap;
    width: 100%;
    background: transparent;
    overflow: visible;
  }

  @include on-desktop {
    position: relative;
    height: 100%;
    padding: 0 0 $v-gap;
    width: 100%;
    background: transparent;
    overflow: visible;

    &__filters {
      margin-right: 40px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: $screen-sm) and (width <= 1215px) {
    .ui-card__footer {
      display: flex;
      align-items: normal;
      margin-top: 20px;
    }
  }

  &__container {
    flex: 1 0 0;
    max-width: 100%;

    @include on-mobile {
      width: 100%;
    }
  }

  &__cards {
    flex: 1 1 auto;
  }

  &__header {
    &-title {
      margin: 16px 0;

      @include on-desktop {
        margin: 0 0 20px;
      }
    }

    &-control-panel {
      margin: 20px 0 15px;
    }

    &--expanded {
      margin-bottom: 20px;
    }
  }

  &__body {
    @include gap(24px, true);

    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }

  &__load-later {
    margin-top: 24px;
  }

  & .page-footer {
    margin-bottom: 100px;

    @include on-mobile {
      margin-bottom: 0;
    }
  }

  &__scroll-top-button {
    position: absolute;
    right: 12px;
    bottom: 32px;
    z-index: 100;
    display: none;

    &--visible {
      display: block;
    }

    .ui-button-wrapper {
      .ui-button {
        width: 48px;
        height: 48px;
        box-shadow: 0 4px 16px 0 #0b082729;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__emission-card {
    & .journey-list__emission {
      justify-content: start;
    }

    & .ui-card__body {
      padding: 8px 20px;

      @include on-desktop {
        padding: 12px 32px;
      }
    }

    & .font-icon-info {
      align-items: center;

      @include on-desktop {
        transform: translateY(3px);
      }
    }
  }
}
