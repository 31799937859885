@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.seat-selection {
  .seat-selection__deck-wrapper {
    margin: 20px;

    @include on-desktop {
      margin: 40px;
    }
  }

  &__lights {
    position: absolute;
    top: -4px;
    width: 30px;
    height: 3px;
    background: $color-seat-lights-bg;
    border-radius: 20px 20px 0 0;

    &--left {
      left: 45px;

      @include on-desktop {
        top: 60px;
        right: -18px;
        transform: rotate(270deg);
      }
    }

    &--right {
      right: 45px;

      @include on-desktop {
        top: unset;
        bottom: 60px;
        right: -18px;
        transform: rotate(270deg);
      }
    }
  }
}
