@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.journey-card {
  &__fares {
    overflow: auto;
    padding: 16px 4px;

    hr {
      width: calc(100% - 20px);
      align-self: center;
      border: none;
      border-top: 1px solid $color-journey-card-fares-border;
      margin: 0;
    }

    @include on-desktop {
      @include gap(10px, true);

      border-left: 1px dashed $color-journey-card-fares-border;
      flex: 0 0 auto;
      flex-basis: 36.3333%;
      max-width: 36.3333%;
      padding: 20px;

      > div:not(:last-child) {
        border: none;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    * {
      font-family: $font-secondary;
    }

    &-card {
      padding: 12px 16px;
      cursor: pointer;
      border-radius: 8px;

      &--selected {
        background: $color-journey-card-fares-selected;
      }

      &:hover {
        background: $color-journey-card-fares-hover;
      }

      @include on-desktop {
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px;
      }

      &-title {
        @include font(14px, 18px, 400);

        max-width: 190px;
        margin-right: 11px;

        &--selected {
          font-weight: 600;
        }
      }

      &-tickets {
        @include font(12px, 16px, 500);

        color: $color-journey-card-tickets-left;
        white-space: nowrap;
      }

      &-price {
        white-space: nowrap;
      }

      [type='button'] {
        @include font(14px, 20px, 600);

        height: 32px;
        min-width: 120px;
        padding: 4px 8px;

        @include on-desktop {
          height: 36px;
          width: 82px;
          padding: 8px;
        }
      }
    }
  }
}
