@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.seat-selection {
  &__footer {
    padding: 20px 20px 0;
    margin-top: auto;

    @include on-desktop {
      width: max-content;
      min-width: 320px;
      margin-left: auto;
    }

    &-title {
      @include font(16px, 24px, 400);

      color: $color-seat-footer-fg;
    }

    &-limit {
      &--warning {
        color: $color-seat-footer-error;
        font-weight: 500;
      }
    }

    &-price {
      @include font(20px, 24px, 600);

      margin-left: min(12px);
    }

    &-alert {
      @include font(12px, 16px, 400);

      font-family: $font-secondary;
      color: $color-seat-footer-error;
    }

    .ui-tooltip {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .ui-tooltip-content-left,
    .ui-tooltip-content-top {
      @include font(14px, 20px, 400);

      color: $black;
      right: calc(100% + 30px);
      max-width: 200px;
    }

    * {
      font-family: $font-secondary;
    }
  }
}
