@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.passenger-dropdown {
  position: relative;

  .passenger-dropdown__input-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    outline: 1px solid $color-dropdown-outline;
    transition: outline-color 150ms;
    padding: 8px 0 8px 12px;
    border-radius: 8px;
    background: $white;
    user-select: none;
    cursor: pointer;

    &:hover {
      outline: 1px solid $color-dropdown-hover;
      cursor: pointer;
    }

    &.passenger-dropdown__input-wrapper--active {
      outline: 2px solid $color-dropdown-focus;
    }

    &.passenger-dropdown__input-wrapper--error {
      outline: 2px solid $color-input-error;
    }
  }

  &__icon {
    display: flex;
    color: $color-grey-40;
  }

  &__label {
    position: absolute;
    user-select: none;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    color: $color-dropdown-label-fg;
    z-index: 10;
    transition:
      transform 150ms ease-out,
      font-size 150ms ease-out;
    cursor: pointer;
    line-height: 18px;

    &.passenger-dropdown__label--focused {
      font-size: 12px;
      transform: translateY(-100%);
    }
  }

  &__value {
    @include font(16px, 24px, 500);

    position: relative;
    flex-grow: 1;
    height: 40px;
    font-family: Inter, sans-serif;
    color: $color-dropdown-fg;
    padding-top: 16px;
  }

  &__popup {
    padding: 16px;
    width: 100%;
    text-align: start;
    overflow: hidden auto;
  }

  &__description {
    font-family: $font-secondary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
  }

  &__error-message {
    font-size: 12px;
    font-weight: 400;
    color: $color-input-error;
    padding-top: 4px;

    .font-icon {
      color: inherit;
    }

    span {
      margin-right: 4px;
    }
  }

  &__tabs {
    overflow: auto;

    & .ui-tabs__header-wrapper {
      flex: 1;
      margin-bottom: 16px;

      div {
        flex: 1;
      }
    }

    & .ui-dropdown {
      &__list {
        position: static;
        margin-bottom: 10px;
      }
    }

    & .passenger-list__age-dropdown .ui-dropdown__list {
      position: absolute;
    }
  }

  &__discounts {
    padding: 0 5px 5px;

    &-label {
      @include font(16px, 24px, 600);

      span:first-child {
        color: $color-dropdown-passenger-fg;
      }
    }

    & .ui-divider {
      margin: 16px 0;
    }
  }
}
